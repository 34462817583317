import { render, staticRenderFns } from "./SubspecialtiesComponent.vue?vue&type=template&id=144827a2&scoped=true"
import script from "./SubspecialtiesComponent.vue?vue&type=script&lang=js"
export * from "./SubspecialtiesComponent.vue?vue&type=script&lang=js"
import style0 from "./SubspecialtiesComponent.vue?vue&type=style&index=0&id=144827a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144827a2",
  null
  
)

export default component.exports